import { PAGE_SIZE_DEFAULT } from "src/contants/pageSize";
import { formatDateYMD } from "src/helpers/date";
import { formatDateToUTCZeroTime } from "src/utils/newUtils/formatDate";

interface IDataListP2PExchangeOffersAgent {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IListP2PExchangeOffersAgentState {
    data: IDataListP2PExchangeOffersAgent;
    isFetching: boolean,
    filter: object,
    pagination: number
}

export const initialListP2PExchangeOffersAgentState: IListP2PExchangeOffersAgentState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        startDate: formatDateToUTCZeroTime(
            new Date().setDate(new Date().getDate() - 6)
        ),
        endDate: formatDateToUTCZeroTime(new Date(), true),
    },
    pagination: 0,
}

