import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';
import { formatDateToUTCZeroTime } from 'src/utils/newUtils/formatDate';
interface IDataListP2PExchangeTransactionsAgent {
    data: {
        items: any[];
        total: number;
    };
    error: any;
    success: boolean;
}

export interface IListP2PExchangeTransactionsAgentState {
    data: IDataListP2PExchangeTransactionsAgent;
    isFetching: boolean,
    filter: object,
    pagination: number
}

export const initialListP2PExchangeTransactionsAgentState: IListP2PExchangeTransactionsAgentState = {
    data: {
        data: {
            items: [],
            total: 0
        },
        error: null,
        success: false
    },
    isFetching: false,
    filter: {
        pageNumber: 1,
        pageSize: PAGE_SIZE_DEFAULT,
        startDate: formatDateToUTCZeroTime(
            new Date().setDate(new Date().getDate() - 6)
        ),
        endDate: formatDateToUTCZeroTime(new Date(), true),
    },
    pagination: 0,
}

